import React from "react";
import { Cluster } from "../../atoms/layouts";
import Text from "../text";
import styled from "styled-components";
import { noop } from "../../../util/general";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./RadioButtonWithLabel.theme";

const HiddenRadioInput = styled.input`
  // can still select the element with the keyboard, but it's invisible and doesn't interfere with the spacing of other elements around it
  position: absolute;
  opacity: 0;
  margin: 0;
`;

const Circle = styled.div`
  // this exists to override the default browser's radio circle
  flex-shrink: 0;
  margin-right: 8px;
  width: 1.5rem;
  height 1.5rem;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 50%;
  box-sizing: border-box;
  padding: 2px;
  :after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: ${({ activeColor }) => activeColor};
    border-radius: 50%;
    transform: scale(0);  
  }  
`;

const InputAndLabelContainer = styled(Cluster)`
  overflow: visible;
  ${HiddenRadioInput}:checked + label ${Circle}:after {
    transform: scale(0.85);
    transition: transform 0.15s;
  }
  ${HiddenRadioInput}:checked + label ${Circle} {
    border: ${({ borderColor }) => `1px solid ${borderColor};`}
  }
  ${HiddenRadioInput}:focus + label ${Circle} { {
    box-shadow: ${({ borderColor }) => `0px 0px 2px 1px ${borderColor};`}
  }
`;

const RadioButtonWithLabel = ({
  id = "",
  value = "",
  labelText = "",
  groupName,
  setValue,
  ariaInvalid,
  themeValues,
  index,
  handleChange = noop, // optional, for custom event handling in ingesting app
  field,
  config,
  disabled = false
}) => {
  const getDefaultChecked = (value, idx) => {
    const selectionExistsInConfig = config
      ?.map(c => c.value)
      .includes(field.rawValue);

    if (selectionExistsInConfig) {
      // if exists, selection comes from the redux-freeform state
      return field.rawValue === value;
    }
    // fallback to first option as default selection
    return idx === 0;
  };
  return (
    <InputAndLabelContainer
      align="center"
      childGap="0.5rem"
      borderColor={
        disabled ? themeValues.inactiveBorderColor : themeValues.activeColor
      }
    >
      <HiddenRadioInput
        disabled={disabled}
        aria-invalid={ariaInvalid}
        style={{ marginTop: 0 }}
        type="radio"
        name={groupName}
        id={id}
        value={value}
        onChange={e => {
          setValue(e.target.value);
          handleChange(e);
        }}
        defaultChecked={getDefaultChecked(value, index)}
      />
      <Text
        as="label"
        htmlFor={id}
        extraStyles={`
          font-size: 1rem;
          display: flex; 
          width: 100%;
          :hover {
            cursor: pointer;
          }
        `}
      >
        <Circle
          activeColor={
            disabled ? themeValues.disabledColor : themeValues.activeColor
          }
          borderColor={themeValues.inactiveBorderColor}
        />
        {labelText}
      </Text>
    </InputAndLabelContainer>
  );
};

export default themeComponent(
  RadioButtonWithLabel,
  "RadioButtonWithLabel",
  fallbackValues
);
