import { v5 as uuidv5 } from "uuid";
import { createSelector } from "reselect";

const DIGEST_NAMESPACE = "77e5daa9-0f63-48a2-8841-c506becd99f6";
export const STANDARD_ITEM_TYPE = "STANDARD";
export const BASIC_ITEM_TYPE = "BASIC";

export const computeTotal = items =>
  items.reduce((acc, curr) => acc + curr.amount, 0);

export const getPaymentConfigs = state => state?.global?.payment?.data ?? {};

const getCart = state => state.cart;

const getBasicItems = state => state.basicItems;

const getStandardItems = state => state.standardItems;

const getAllItems = state => state.items;

const getDescription = item => item?.description ?? "";

const getSubDescription = item => item?.subDescription ?? "";

const getIdentifier = item => item?.identifier ?? "";

const getAmount = item => item.amount;

export const getDefaultConfig = createSelector(
  [getPaymentConfigs],
  configs => configs.default
);

const createAttributeValues = createSelector(
  [getDescription, getSubDescription, getIdentifier, getAmount],
  (desc, subDesc, identifier, amount) => [desc, subDesc, identifier, amount]
);

const createNameString = createSelector(
  [createAttributeValues],
  attributeValues => attributeValues.reduce((acc, curr) => `${acc} ${curr}`, "")
);

export const createDigestValue = createSelector(
  [createNameString],
  nameString => uuidv5(nameString, DIGEST_NAMESPACE)
);

export const getCartPaymentConfig = createSelector(
  [getCart],
  cart => cart.config.payment
);

export const getSubClientSlug = createSelector(
  [getCart],
  cart => cart.config.subClientSlug
);

export const getCartAccountNumber = createSelector(
  [getCart],
  cart => cart.config.accountId
);

export const getSubClientPaymentConfig = createSelector(
  [getSubClientSlug, getPaymentConfigs, getDefaultConfig],
  (subClientSlug, paymentConfigs, defaultConfig) =>
    paymentConfigs?.[subClientSlug] ?? defaultConfig
);

export const getPaymentConfigOptions = createSelector(
  [getCartPaymentConfig],
  config => config.options
);

export const getPaymentConfigCustomAttrs = createSelector(
  [getCartPaymentConfig],
  config => config.customAttributes
);

export const getPaymentConfigServiceName = createSelector(
  [getCartPaymentConfig],
  config => config.serviceName
);

export const getVisitIdFromCart = createSelector(
  [getCart],
  cart => cart?.config?.visitId
);

export const getCartLineItems = createSelector([getCart], cart => cart.items);

export const getAccountLookupConfigKey = createSelector(
  [getCart],
  cart => cart?.config?.accountLookupConfigKey
);

// check to make sure item isn't already in item set before adding
const addNewItems = (state, itemSelector, items) =>
  items.reduce(
    (acc, curr) =>
      acc.some(item => item.digest === curr.digest) ? acc : [...acc, curr],
    itemSelector(state)
  );

export const validateAndAddItems = (
  state,
  newItems,
  newItemType,
  optionalItemIdKey
) => {
  // add item digests (basically UUIDs) to each new item
  const itemsWithDigests = newItems.map(item => ({
    ...item,
    digest: createDigestValue(item),
    optionalItemId: item?.customAttributes.find(
      attr => attr.key === optionalItemIdKey
    )?.value
  }));

  // add toast notifications only for new items
  const newItemsWithDigests = itemsWithDigests
    .filter(item => !state.items.find(({ digest }) => digest === item.digest))
    .map(item => item.digest);

  const toasts =
    newItemsWithDigests.length > 0
      ? [
          {
            id: newItemsWithDigests[0],
            message: `${newItemsWithDigests.length} Item${
              newItemsWithDigests.length > 1 ? "s" : ""
            } Added`
          }
        ]
      : [];

  // depending on item type, get updated items or current items in state
  const newBasicItems =
    newItemType === BASIC_ITEM_TYPE
      ? addNewItems(state, getBasicItems, itemsWithDigests)
      : state.basicItems;
  const newStandardItems =
    newItemType === STANDARD_ITEM_TYPE
      ? addNewItems(state, getStandardItems, itemsWithDigests)
      : state.standardItems;

  // return updated collection of basic items, standard items, and all items;
  return {
    basicItems: newBasicItems,
    standardItems: newStandardItems,
    items: [...newBasicItems, ...newStandardItems],
    toasts
  };
};

export const removeItem = (state, itemToRemove) => {
  const filterFunction = item => item.digest !== itemToRemove.digest;
  const updatedBasicItems = getBasicItems(state).filter(filterFunction);
  const updatedStandardItems = getStandardItems(state).filter(filterFunction);
  const updatedAllItems = getAllItems(state).filter(filterFunction);
  return {
    basicItems: updatedBasicItems,
    standardItems: updatedStandardItems,
    items: updatedAllItems
  };
};

export const removeToast = (toasts, id) =>
  toasts ? toasts.filter(toast => toast.id !== id) : [];
