const actions = {
  ADD_COMPONENT: "ADD_COMPONENT",
  COMPONENT_CHANGED: "COMPONENT_CHANGED",
  CHANGE_SCREEN: "CHANGE_SCREEN",
  STORE_API_RESPONSE: "STORE_API_RESPONSE",
  INSERT_DYNAMIC_COMPONENT: "INSERT_DYNAMIC_COMPONENT",
  CHANGE_DYNAMIC_COMPONENT: "CHANGE_DYNAMIC_COMPONENT",
  BIND_DATA: "BIND_DATA",
  RESET_WORKFLOW_STATE: "RESET_WORKFLOW_STATE",
  INITIAL_WORKFLOW_RECEIVED: "INITIAL_WORKFLOW_RECEIVED",
  CLEAR_BOUND_DATA: "CLEAR_BOUND_DATA",
  DO_LOOKUP: "DO_LOOKUP",
  DO_SEARCH: "DO_SEARCH",
  DO_REST_CALL: "DO_REST_CALL",
  CLEAR_NETWORK_ERROR: "CLEAR_NETWORK_ERROR",
  SET_ACTIVE_WORKFLOW: "SET_ACTIVE_WORKFLOW",
  WORKFLOW_NOT_FOUND: "WORKFLOW_NOT_FOUND",
  SET_WORKFLOW_PAGE_NAME: "SET_WORKFLOW_PAGE_NAME",
  DISPLAY_ITEM_WARNING: "DISPLAY_ITEM_WARNING",
  SET_ONE_ITEM_IN_CART_WARNING: "SET_ONE_ITEM_IN_CART_WARNING"
};

export const resetWorkflowState = () => ({
  type: actions.RESET_WORKFLOW_STATE
});

export const clearBoundData = component => ({
  type: actions.CLEAR_BOUND_DATA,
  payload: { component }
});

export const doLookup = lookupOptions => ({
  type: actions.DO_LOOKUP,
  payload: { lookupOptions }
});

export const doSearch = payload => ({
  type: actions.DO_SEARCH,
  payload
});

export const doRestCall = payload => ({
  type: actions.DO_REST_CALL,
  payload: { resultsLocation: ["responseData"], ...payload }
});

export const bindData = ({ location, data }) => ({
  type: actions.BIND_DATA,
  location,
  data
});

export const changeScreen = (screenIndex, isBack = false) => ({
  type: actions.CHANGE_SCREEN,
  screenIndex,
  isBack
});

export const setWorkflowPageName = workflowPageName => ({
  type: actions.SET_WORKFLOW_PAGE_NAME,
  payload: {
    workflowPageName
  }
});

export const clearNetworkError = () => ({
  type: actions.CLEAR_NETWORK_ERROR
});

export const setActiveWorkflow = payload => ({
  type: actions.SET_ACTIVE_WORKFLOW,
  payload
});

export const workflowNotFound = () => ({
  type: actions.WORKFLOW_NOT_FOUND
});

export const displayItemWarning = warningStatus => ({
  type: actions.DISPLAY_ITEM_WARNING,
  payload: {
    warningStatus
  }
});

export const setOneItemInCartWarning = warningMessage => ({
  type: actions.SET_ONE_ITEM_IN_CART_WARNING,
  payload: {
    warningMessage
  }
});

export default actions;
