import {
  GREY_CHATEAU,
  MATISSE_BLUE,
  MANATEE_GREY
} from "../../../constants/colors";
const activeColor = MATISSE_BLUE;
const disabledColor = MANATEE_GREY;
const inactiveBorderColor = GREY_CHATEAU;

export const fallbackValues = {
  activeColor,
  disabledColor,
  inactiveBorderColor
};
